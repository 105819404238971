.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.App h1 {
  font-size: 4rem;
  font-weight: 700;
  margin-bottom: 0.25rem;
}

.App h2 {
  font-size: 1.5rem;
  font-weight: 400;
  margin-top: 1rem;
  margin-bottom: 0.25rem;
}

.App form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 25%;
}

.App input {
  display: block;
  font-size: 1.5rem;
  font-weight: 400;
  padding: 0.5rem;
  margin: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.correct {
  border: 2px solid green !important;
}

.incorrect {
  border: 2px solid red !important;
}

.App button {
  font-size: 1.5rem;
  font-weight: 400;
  padding: 0.5rem;
  margin: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
}

.App button:hover {
  background-color: #eee;
}
